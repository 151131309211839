class LanguageService {
    constructor() {
      this.selectedLangTranslation = {};
    }
  
    async getLanguageTranslationKey(aws_url) {
		try {
			const response = await fetch(aws_url);
			if (response.ok) {
				return await response.json();
			}
		} catch (error) {
		}
    }
	
  }
  export default new LanguageService();
  