<template>
    <div class="news-marquee-sec" v-if="siteSettings && siteSettings.announcement && siteSettings.announcement.msg">
        <div class="marquee-box">
            <h4><i class="fa fa-microphone"></i> News</h4>
            <marquee :style="{ color: siteSettings?.announcement?.color_code }" direction="left" scrollamount="5" behavior="scroll">{{ siteSettings?.announcement?.msg }}</marquee>
        </div>
    </div>
</template>
<script>
export default{
    name:'MarqueeNews',
    computed: {
			siteSettings() {
				return this.$store.getters.siteSettings;
			},
		},
}
</script>