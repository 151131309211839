export const SPORTS_TYPES = 
[
    {
        id:'ALL',
        type:'All Sport',
        gameType: 111
    },
    {
        id:4,
        type:'Cricket',
        gameType: 1
    },
    {
        id:1,
        type:'Soccer',
        gameType: 1
    },
    {
        id:2,
        type:'Tennis',
        gameType: 1
    },
    {
        id:'casino',
        type:'Casino',
        gameType: 2
    },
    {
        id:'worli',
        type:'Matka',
        gameType: 2
    },
    {
        id:'sportbook',
        type:'Sports book',
        gameType: 2
    }
]

export var NEW_STATIC_GAMES =[
    {
        id: 'all',
        type: 'All'
    },
    {
        id: "dw",
        type: 'Deposit/Withdraw'
    },
    {
        id: "matka",
        type: 'Matka'
    },
    {
        id: "casino",
        type: 'Casino'
    },
    {
        id: "sportsbook",
        type: 'Sportsbook'
    },
    {
        id: "premium",
        type: 'Premium '
    },
    {
        id: "virtual_sport",
        type: 'Virtual sports'
    },
    {
        id: "fantasy_cricket",
        type: 'Fantasy cricket'
    }
]

export var STATEMENT_TYPE_GAMES =[
    {
        id: 'all',
        type: 'All'
    },
    {
        id:4,
        type:'Cricket'
    },
    {
        id:1,
        type:'Soccer'
    },
    {
        id:2,
        type:'Tennis'
    },
    {
        id: "dw",
        type: 'Deposit/Withdraw'
    },
    {
        id: "matka",
        type: 'Matka'
    },
    {
        id: "casino",
        type: 'Casino'
    },
    {
        id: "sportsbook",
        type: 'Sportsbook'
    },
    {
        id: "premium",
        type: 'Premium '
    },
    {
        id: "virtual_sport",
        type: 'Virtual Sports'
    },
    {
        id: "fantasy_cricket",
        type: 'Fantasy Cricket'
    }
]

export const BET_STATUS =[
    {
        id: 2,
        type: 'settled'
    },
    {
        id: 4,
        type: 'void'
    },
    {
        id: 5,
        type: 'invalid'
    }
]
