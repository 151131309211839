const CricketFightModule  = () => import(/* webpackChunkName: "cricket-fight-module" */ './views/module.vue');
const CricketFight  = () => import(/* webpackChunkName: "cricket-fight" */ './views/cricketFight.vue');
const MyContests  = () => import(/* webpackChunkName: "my-contests" */ './views/MyContests.vue');
const MyContestBetDetails  = () => import(/* webpackChunkName: "my-contest-bet-details" */ './views/MyContestBetDetails.vue');
const MatchDetails  = () => import(/* webpackChunkName: "match-details" */ './views/MatchDetails.vue');
const PlaceBet  = () => import(/* webpackChunkName: "place-bet" */ './views/PlaceBet.vue');

const CricketFightRoutes = {
    path: '/',
    component: CricketFightModule ,
    children: [
        {
            path: 'cricket-fight',
            name: 'CricketFight',
            component: CricketFight,
        },
        {
            path: 'cricket-fight/my-contests',
            name: 'MyContests',
            component: MyContests
        },
        {
            path: 'cricket-fight/my-contests/:id',
            name: 'MyContestBetDetails',
            component: MyContestBetDetails
        },
        {
            path: 'cricket-fight/:id',
            name: 'MatchDetails',
            component: MatchDetails
        },
        {
            path: 'cricket-fight/:id/place-bet',
            name: 'PlaceBet',
            component: PlaceBet
        },
    ],
}

export default CricketFightRoutes;