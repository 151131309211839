const ProfileModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ './views/Profile.vue');
const ChangePassword = () => import(/* webpackChunkName: "change-password" */ './views/ChangePassword.vue');
const PasswordHistory = () => import(/* webpackChunkName: "password-history" */ './views/PasswordHistory.vue');
const ActiveLog = () => import(/* webpackChunkName: "active-log" */ './views/ActiveLog.vue');

const ProfileRoutes = {
    path: '/',
    component: ProfileModule,
    children: [
        {
            path: 'profile',
            name: 'profile',
            component: Profile
        },
        {
            path: 'change-password',
            name: 'change-password',
            component: ChangePassword
        },
        {
            path: 'password-history',
            name: 'password-history',
            component: PasswordHistory
        },
        {
            path: 'active-log',
            name: 'active-log',
            component: ActiveLog
        },
    ],
}   

export default ProfileRoutes;