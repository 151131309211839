<template>
    <div id="ageconfirmationModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="login-popup-content">
                    <div class="modal-body ageconfirmationModalclass">
                        <div class="login-panel-container confirm-container">
                            <div class="confirm-text">
                                <div class="header-popup">
                                    <a data-bs-dismiss="modal" aria-label="Close" class="ui-link">
                                        <i class="fas fa-times"></i>
                                    </a>
                                </div>
                                <div class="text1 text-confirm-bold">
                                    {{ translatedLangData('non-gambling-territories', 'Non-Gambling Territories') }}.
                                </div>
                                <hr class="confirm-line" />
                                <div class="text2 text-capitalize">
                                    {{ translatedLangData("non-gambling-territories-content1", "Connecting to our site from non gambling countries, it will be User's responsibility to ensure that their use of the service is lawful") }}.
                                </div>
                            </div>
                            <div class="confirm-text">
                                <div class="text1 text-confirm-bold">
                                    {{ translatedLangData('underage-gambling-is-prohibited', 'Underage Gambling Is Prohibited') }}.</div>
                                <hr class="confirm-line" />
                                <div class="text2">
                                    {{ translatedLangData('pls-confirm-you-are-18', 'Please Confirm If You Are 18 Years Old And Above As Of Today') }}.
                                </div>
                            </div>
                            <div class="confirm-button">
                                <a @click="confirmAge" class="btn-verification btn-confirm cursor-pointer" tabindex="0">
                                    <span>{{ translatedLangData('confirm', 'Confirm') }}</span>
                                </a>
                                <button data-bs-dismiss="modal" class="btn-verification cursor-pointer">
                                    <span>{{ translatedLangData('exit', 'Exit') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    name: "AgeConfirmationLoginModal",
    inject:['translatedLangData'],
    methods: {
        confirmAge() {
            const modal = Modal.getInstance(document.getElementById('ageconfirmationModal'));
            modal.hide();
            this.$router.push('/login');
        }
    }
}
</script>

<style>

</style>