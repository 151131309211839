<template>
    <div class="container">
        <footer class="mobile-footer-menu-hide" loading="lazy" v-if="hideFooterRoutes()">
            <nav class="mobile-footer-menu">
                <ul>
                    <li @click="closeModalFun()">
                        <router-link to="/" class="ui-link" :class="{active : this.$route.name == 'Home'}" >
                            <img src="@/assets/images/home.svg" class="icon-home">
                            {{ translatedLangData('home', 'Home') }}
                        </router-link>
                    </li>

                    <li @click="closeModalFun()">
                        <router-link to="/inplay" class="ui-link" :class="{active : this.$route.name == 'inplay'}">
                            <img src="@/assets/images/timer.svg" class="icon-inplay">
                            {{ translatedLangData('inplay', 'In-Play') }}
                        </router-link>
                    </li>

                    <li @click="closeModalFun()" class="main-nav">
                        <router-link to="/sports" class="ui-link sports-tabs-icon"  :class="{active : this.$route.name == 'SportsItem'}">
                            <img src="@/assets/images/trophy.svg" class="icon-sports">
                            <span class="">{{ translatedLangData('sport', 'Sports') }}</span>
                        </router-link>
                    </li>

                    <!-- <li @click="closeModalFun()" class="cursor-pointer">
                        <a @click="clickOnMultimarket()" class="ui-link" :class="{active : this.$route.name == 'multi-market'}">
                            <img src="@/assets/images/pin-white-footer.svg" class="icon-pin">
                            {{ translatedLangData('multi-market', 'Multi Market') }}
                        </a>
                    </li> -->

                    <li class="cursor-pointer">
                        <a @click="clickOnMiniGame()" class="ui-link" :class="{ 'active': miniGameActive }">
                            <img src="@/assets/images/ChipRotate.gif" class="icon-pin">
                            {{ translatedLangData('casino', 'Casino') }}
                        </a>
                    </li>

                    <li @click="closeModalFun()" class="cursor-pointer">
                        <a @click="clickOnAccount()" class="ui-link" :class="{active : this.$route.name == 'mobile-account'}">
                            <img src="@/assets/images/user.svg" class="icon-account">
                            {{ translatedLangData('account','Account')}}
                        </a>
                    </li>
                </ul>
            </nav>
        </footer>        
    </div>
    <div ref="openBets_modal" style="display: none;" data-bs-dismiss="modal" aria-label="Close" data-bs-target="#openBets"></div>
    <AgeConfLoginModal></AgeConfLoginModal>
    <div class="game-loby-space" v-if="miniGameActive && iFrameUrl"></div>
    <div class="game-loby-iframe" v-if="miniGameActive && iFrameUrl">
        <div class="game-loby-close" @click="closeMiniGame()">&#x2716;</div>
        <iframe title="iframe_lobby" :src="iFrameUrl"></iframe>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import AgeConfLoginModal from '@/shared/components/modal/AgeConfirmationLoginModal.vue';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls'
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'MobileFooter',
    inject:['translatedLangData'],
    data() {
        return {
            miniGameActive: false,
            iFrameUrl: null,
        }
    },
    components: {
        AgeConfLoginModal
    },
    methods: {
        clickOnAccount() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'mobile-account'});
            }
            else {
                const modal = new Modal(document.getElementById('ageconfirmationModal'));
                modal.show();
            }
        },
        clickOnMiniGame() {
             if (this.checkIsLogin()) {
                this.miniGameActive = true;
                this.getMiniGame();
                document.body.classList.add('miniGameActive');

            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        getMiniGame() {
            let miniGameData = this.$store.getters.gamesData.sections.find(gm => gm.slug == 'yuvi-games') || null;
            miniGameData = miniGameData && miniGameData.games[0];
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + miniGameData?.game_code + '/' + miniGameData?.sm_id, {}, { headers }).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data.data.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL Not Found.');
                            this.closeMiniGame();
                            return;
                        }
                    }

                }
            }).catch(error => {
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeMiniGame();
                }
            });
        },
        closeMiniGame() {
            this.miniGameActive = false;
        },
        clickOnMultimarket() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'multi-market'});
            }
            else {
                const modal = new Modal(document.getElementById('ageconfirmationModal'));
                modal.show();
            }
        },       
        checkIsLogin(){
            return this.$store.getters.isAuthenticated;
        },
        closeModalFun() {
            this.$refs.openBets_modal?.click();
            // const modals = document.querySelectorAll('.modal.show');
            // modals.forEach(modal => {
            //     const modalInstance = bootstrap.Modal.getInstance(modal);
            //     if (modalInstance) {
            //         modalInstance.hide();
            //     }
            // });
        },
        hideFooterRoutes() {
			let routeName = this.$route.name;
			let restrictedRoutes = [
                'matka',
                'matka-details'
			]
			return !restrictedRoutes.includes(routeName);
		},
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>