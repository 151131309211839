const DashboardModule = () => import(/* webpackChunkName: "dashboard-module" */ './views/Module.vue'); 
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue');
const SportsItem = () => import(/* webpackChunkName: "dashboard" */ './views/SportsItem.vue');


const DashboardRoutes =   {
    path: '/',
    component: DashboardModule,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: 'sports',
        name: 'SportsItem',
        component: SportsItem
      },
    
    ],
  }

export default DashboardRoutes;