<template>
    <div class="modal-content">
        <div class="modal-header">
            <div class="thm-heading">
                <h3>{{ translatedLangData('view-bets', 'View Bets') }}</h3>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="betType == 'sport'">
            <div>
                <div class="accordion" id="accordionExample" >
                <div class="accordion-item" v-if="openBets && openBets?.main_market && openBets?.main_market?.length">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamBetsMainMarket1"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">
                                            {{ getIndex('main_market') }}.</span>
                                            {{ translatedLangData('match-odds', 'Match Odds') }} ({{ openBets?.main_market?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets?.main_market?.[0]?.bet_timestamp_date) }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="teamBetsMainMarket1" class="accordion-collapse collapse" aria-labelledby="headingOne" 
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <BetsModal v-for="(bet, bet_index) in openBets?.main_market" :key="bet_index" :bet="bet" />
                        </div>
                    </div>
                </div>
                <div class="accordion-item" v-if="openBets && openBets?.bookmakers && openBets?.bookmakers?.length">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamBetsMainMarket2"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">{{ getIndex('bookmakers') }}.</span>
                                        {{ translatedLangData('bookMaker', 'Bookmaker') }} ({{ openBets?.bookmakers?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets.bookmakers[0].bet_timestamp_date) }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="teamBetsMainMarket2" class="accordion-collapse collapse" aria-labelledby="headingTwo" 
                        data-bs-parent="#accordionExample">    
                        <div class="accordion-body">               
                            <BetsModal v-for="(bet, bet_index) in openBets.bookmakers" :key="bet_index" :bet="bet" />
                        </div> 
                    </div>
                </div>
                <div class="accordion-item" v-if="openBets && openBets?.fancies && openBets?.fancies?.length">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamBetsMainMarket3"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">{{ getIndex('fancies') }}.</span>
                                        {{ translatedLangData('fancies', 'Fancy') }} ({{ openBets?.fancies?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets?.fancies?.[0]?.bet_timestamp_date) }}</span>
                                </div>
                            </div>

                        </button>
                    </h2>
                    <div id="teamBetsMainMarket3" class="accordion-collapse collapse"
                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <BetsModal v-for="(bet, bet_index) in openBets?.fancies" :key="bet_index" :bet="bet" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div v-else-if="betType == 'fantasy_cricket'" class="sat-bet-ui-wrapper space-accordion-body">
            <div class="accordion" id="accordionExample" >
                <div class="accordion-item" v-if="openBets && openBets?.bet_with_ball && openBets?.bet_with_ball?.length">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed fant-bet-det" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamFanBetsMainMarket1"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">{{ getIndexNo('bet_with_ball') }}.</span>
                                        {{ translatedLangData('bet-with-ball', 'Bet With Ball') }} ({{ openBets?.bet_with_ball?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets.bet_with_ball[0].event_date) }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="teamFanBetsMainMarket1" class="accordion-collapse collapse fant-bet-cont" aria-labelledby="headingOne" 
                        data-bs-parent="#accordionExample">
                            <FantasyBetsData v-for="(bet, bet_index) in openBets.bet_with_ball" :key="bet_index" :bet="bet" />          
                    </div>
                </div>
                <div class="accordion-item" v-if="openBets && openBets?.bet_with_run && openBets?.bet_with_run?.length">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed fant-bet-det" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamFanBetsMainMarket2"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">{{ getIndexNo('bet_with_run') }}.</span>
                                        {{ translatedLangData('bet-with-run', 'Bet With Run') }} ({{ openBets?.bet_with_run?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets.bet_with_run[0].event_date) }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="teamFanBetsMainMarket2" class="accordion-collapse collapse fant-bet-cont" aria-labelledby="headingTwo" 
                        data-bs-parent="#accordionExample">                    
                        <FantasyBetsData v-for="(bet, bet_index) in openBets.bet_with_run" :key="bet_index" :bet="bet" />
                    </div>
                </div>
                <div class="accordion-item" v-if="openBets && openBets?.bet_with_team && openBets?.bet_with_team?.length">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed fant-bet-det" type="button"
                            data-bs-toggle="collapse" data-bs-target="#teamFanBetsMainMarket3"
                            aria-expanded="true" aria-controls="teamBetsMainMarket">
                            <div class="match-faq statement-match-faq">
                                <div class="match-head">
                                    <h5>
                                        <span class="number-list">{{ getIndexNo('bet_with_team') }}.</span>
                                        {{ translatedLangData('bet-with-team', 'Bet With Team') }} ({{ openBets?.bet_with_team?.length }})
                                    </h5>
                                </div>
                                <div class="match-con">
                                    <span>{{ convertUTCDateToLocalDateHere(openBets.bet_with_team[0].event_date) }}</span>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="teamFanBetsMainMarket3" class="accordion-collapse collapse fant-bet-cont" aria-labelledby="headingThree" 
                        data-bs-parent="#accordionExample">                    
                        <FantasyBetsData v-for="(bet, bet_index) in openBets.bet_with_team" :key="bet_index" :bet="bet" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="betType  == 'matka'" class="sat-bet-ui-wrapper space-accordion-body">
            <div  class="accordion-item">
                <div class="back-slip accordion-body team-con">
                    <div class="team-con">
                        <div class="team-list-sec">
                            <ul>
                                <li>{{ translatedLangData('event-name', 'Event Name') }}</li>
                                <li class="team-list-wid"> {{ openBets?.event_name }}</li>
                            </ul>
                            <ul>
                                <li>{{ translatedLangData('selection', 'Selection') }}</li>
                                <li class="team-list-wid">{{ openBets?.selection }}</li>
                            </ul>
                            <ul>
                                <li>{{ translatedLangData('placing-time', 'Placing Time') }}</li>
                                <li style="color: #7B7B7B;" class="team-list-wid">
                                    {{ openBets?.bet_place_time ? openBets?.bet_place_time : openBets?.bet_timestamp_date }}                                                        </li>
                            </ul>
                        </div>
                    </div>
                    <div class="team-list">
                        <ul>
                            <li>
                                <span v-if="openBets?.rate">{{ translatedLangData('rate', 'Rate') }}</span> 
                                <span v-else>{{ translatedLangData('odds', 'Odds') }}</span>
                                <span><strong> {{ openBets?.rate ? formatNumber(openBets?.rate) : formatNumber(openBets?.odds) }} </strong> </span>
                            </li>
                            <li>
                                <span>{{ translatedLangData('stake', 'Stake') }}</span>
                                <span><strong> {{ openBets?.stake }}</strong></span>
                            </li>
                            <li class="border-0">
                                <span>{{ translatedLangData('pl', 'PL')}}</span>
                                <span><strong> {{ openBets?.p_l }}</strong></span>
                            </li>
                            <li class="border-0">
                                <span>{{ translatedLangData('digit', 'Digit')}}</span>
                                <span><strong> {{ openBets?.odds }}</strong></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn close-btn thm-but" data-bs-dismiss="modal">{{ translatedLangData('close', 'Close') }}</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import BetsModal from '../components/BetsModal.vue';
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
import FantasyBetsData from '../components/FantasyBetsData.vue';

export default {
    name: 'betDetailsModal',
    inject:['translatedLangData'],
    data() {
        return {
            
        };
    },
    components: {
        BetsModal,
        NoDataFound,
        FantasyBetsData
    },
    props: ['openBets','betType'],
    computed: {
        openBetsFCData() {
            let betHistory = [];
            if (this.openBets.bet_with_team) {
                betHistory = [...betHistory, ...this.openBets.bet_with_team];
            }
            if (this.openBets.bet_with_run) {
                betHistory = [...betHistory, ...this.openBets.bet_with_run];
            }
            if (this.openBets.bet_with_ball) {
                betHistory = [...betHistory, ...this.openBets.bet_with_ball];
            }
            return betHistory;
        },
    },
    methods: {
        formatNumber(value) {
        return value.toFixed(2);
        },
        getIndex(market) {
            const currMarket = this.openBets?.[market];
            if (market === 'main_market' && currMarket?.length) return 1;
            if (market === 'bookmakers' && currMarket?.length) return this.openBets.main_market.length ? 2 : 1;
            if (market === 'fancies' && currMarket?.length) return (this.openBets.main_market.length && this.openBets.bookmakers.length) ? 3 : (this.openBets.main_market.length || this.openBets.bookmakers.length) ? 2 : 1;
        },
        getIndexNo(market) {
            const currMarket = this.openBets?.[market];
            if (market === 'bet_with_ball' && currMarket?.length) return 1;
            if (market === 'bet_with_run' && currMarket?.length) return this.openBets.bet_with_ball.length ? 2 : 1;
            if (market === 'bet_with_team' && currMarket?.length) return (this.openBets.bet_with_ball.length && this.openBets.bet_with_run.length) ? 3 : (this.openBets.bet_with_ball.length || this.openBets.bet_with_run.length) ? 2 : 1;
        },
        convertUTCDateToLocalDateHere(timestamp) {
            return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
        },
        getDateTime(dateTime) {
			return moment.unix(dateTime).format('DD/MM/YYYY hh:mm:ss A')
		},
    }
}
</script>

<style scoped>
.accordion-item {
    border-radius: 0px;
}

.fant-bet-det:focus {
    box-shadow: none;
}
.thm-heading h3 {
    font-size: 15px !important;
    font-weight: 600;
}
</style>


