import moment from 'moment';

export function convertUTCDateToLocalDate(timestamp) {
    return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
}

export function getUtcTimeStampFromDate(date){
    var utcDate = date.toUTCString();
    return new Date(utcDate).getTime() /1000;
}

export function getSubtractedDaysDate(date,days)
{  
    var day=date.getTime() - (days*24*60*60*1000);
    return date.setTime(day);
}




