<template>
    <div class="stake">
        <!-- Button trigger modal -->
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop-stake" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            <i class="fa-sharp fa-solid fa-gear"></i>
                            &nbsp;Setting
                        </h5>
                        <button type="button" ref="close_button" @click="resetChips()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="stakediv">
                            <h3>stake</h3>
                            <dl v-if="chips" id="" class="setting-block stake-setting">
                                <dd ><input type="number" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                        v-model="chips.chip_val_1" class="ng-untouched ng-pristine ng-valid" ></dd>
                                <dd ><input type="number" @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                        v-model="chips.chip_val_2"  class="ng-untouched ng-pristine ng-valid"></dd>
                                <dd ><input type="number"  @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                        v-model="chips.chip_val_3"  class="ng-untouched ng-pristine ng-valid"></dd>
                                <dd ><input type="number"  @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                        v-model="chips.chip_val_4" class="ng-untouched ng-pristine ng-valid" ></dd>
                                <dd ><input type="number" @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                        v-model="chips.chip_val_5"  class="ng-untouched ng-pristine ng-valid"></dd>
                                <dd ><input type="number"  @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                        v-model="chips.chip_val_6" class="ng-untouched ng-pristine ng-valid" ></dd>
                                <dd ><input type="number" @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                        v-model="chips.chip_val_7"  class="ng-untouched ng-pristine ng-valid"></dd>
                                <dd ><input type="number"  @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                        v-model="chips.chip_val_8" class="ng-untouched ng-pristine ng-valid"></dd>
                                <!---->
                                <dd class="col-stake_edit"><a @click="callChangeStakeValue()" class="btn-send ui-link">Save</a></dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { kFormatter } from '@/shared/utils/formatter';

export default {
        name: "StackModal",
        data() {
            return {
                chips: null,
                chipsDummy: null,
                userData: null
            }
        },
        mounted() {
            this.chips = {...this.$store?.getters?.chips};
            this.chipsDummy = {...this.$store?.getters?.chips};
            this.userData = this.$store?.getters?.stateUser;
        },
        methods: {
            callChangeStakeValue() {
                let chipsData = {
                    "chips": {
                        // "id": this.userData?.id,
                        // "user_id": this.userData?.userid,
                        ...this.chips
                    }
                }
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }

                this.loading = true;
                api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status == 200) {
                        this.$store.dispatch('setChips', this.chips);
                        this.$emit('save-btn');
                        this.$refs.close_button.click();
                    } else {

                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
            },
            getKFormatter(num) {
                return kFormatter(num);
            },
            resetChips(){
                this.chips = {...this.$store?.getters?.chips};
            }
        },
        emits: ['close-stake-modal', 'success-modal', 'error-modal','save-btn']
};
</script>