const AuthorizationModule = () => import(/* webpackChunkName: "authorization-module" */ './views/Module.vue');
const SignUp = () => import(/* webpackChunkName: "signup" */ "./views/SignUp.vue");
const ForgetPassword = () => import(/* webpackChunkName: "forget-password" */ "./views/ForgetPassword.vue");
const LoginPage = () => import(/* webpackChunkName: "login" */ "./views/LoginPage.vue");

const AuthorizationRoutes =
{
    path: '/',
    component: AuthorizationModule,
    children: [
        {
            path: "/login",
            name: "login",
            component: LoginPage
        },
        {
            path: "/sign-up",
            name: "sign-up",
            component: SignUp
        },
        {
            path: "/forget-password",
            name: "forget-password",
            component: ForgetPassword
        },
    ]
}



export default AuthorizationRoutes;