<template>
    <div v-if="bet" class="back-slip accordion-body team-con fantasy-container">
        <div class="team-con">
            <!-- <div class="bets-header-title">
                <h6>{{ bet.event_name }}</h6>
            </div> -->
            <div class="team-list-sec">
                <ul>
                    <li class="fw-600">{{translatedLangData('team-name', 'Team Name')}}</li>
                    <li class="team-list-wid">{{ bet.team_name }}</li>
                </ul>
                <ul>
                    <li class="fw-600">{{translatedLangData('placed-date', 'Placed Date')}}</li>
                    <li class="team-list-wid">
                        {{ getDateTime(bet.created_at) || '-' }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="team-list">
            <ul>
                <li>
                    <span class="fw-600">{{translatedLangData('stake', 'Stake')}} </span>
                    <span >{{ bet.stake || '-' }}</span>
                </li>
                <li>
                    <span class="fw-600">{{translatedLangData('profit-loss', 'P&amp;L')}} </span>
                    <span >{{ bet.p_l || '0' }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default{
    name:'BetsModal',
    inject:['translatedLangData'],
    methods:{
        convertUTCDateToLocalDateHere(timestamp) {
            return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
        },
        getDateTime(dateTime) {
			return moment.unix(dateTime).format('DD/MM/YYYY hh:mm:ss A')
		}
    },
    props:['bet'] 
}
</script>

<style>
.text-fw {
    font-weight: 600 !important;
}
.text-val {
    list-style: none;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.team-list-sec ul li span {
    display: inline-block;
    float: right;
    padding-right: 10px;
}
@media screen and (max-width: 479px){
    .team-list-sec ul li {
        font-size: 13px !important;
    }
    .text-val {
        font-size: 13px !important;
    }
}
</style>